<template>
  <div>
    <div>

    </div>
    <!-- 页脚 -->
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "../../components/footer.vue";
export default {
  components: { Footer },
  data () {
    return {}
  }
}
</script>
